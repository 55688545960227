import React from 'react';
import Logo from '../images/logo.png';
import Image from 'react-bootstrap/Image';
import { IoCall } from "react-icons/io5";
import { IoIosMail } from "react-icons/io";
import { BiSupport } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { RiOrganizationChart } from "react-icons/ri";

export default function Header() {
  return (
    <div>
       <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className='container-fluid'>     
  <a className="navbar-brand col-3" href="#"><Image src={Logo} fluid /></a>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav ms-auto">
      <li className="nav-item active">
        <a className="nav-link" href="#"><IoCall/> +91 8625879045</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#"><IoIosMail /> support@netcrontech.com</a>
      </li>
      <li className="nav-item">
       <NavLink to="agent-login"> <a className="nav-link" href="#"><BiSupport /> Agent Login</a></NavLink>
      </li>
      <li className="nav-item">
       <NavLink to="admin-login"> <a className="nav-link" href="#"><RiOrganizationChart/> Admin Login</a></NavLink>
      </li>
      
    </ul>
   <NavLink to="/user-login"><button className='header-button' >LOGIN FOR EXISTING USER</button></NavLink> 
  </div>
  </div>
</nav>
      
    </div>
  )
}
