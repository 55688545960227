import './App.css';
import { BrowserRouter,Route,Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './componants/Home';
import Home from './componants/Home';
import  Login  from './componants/Login';
import AgentLogin from './componants/AgentLogin';
import AdminLogin from'./componants/AdminLogin';
import Admin_dashboard from './componants/admin/Admin_dashboard';
import Super_admin_dashboard from './componants/super_admin/Super_admin_dashboard';
import User_dashboard from './componants/user/User_dashboard';
import Error from './componants/Eroor'
import User_Login from './componants/User_Login';
import SuperAdminLogin from './componants/SuperAdminLogin';


function App() {
  return (
    <div className="App">
    <BrowserRouter>
    <Routes>
    <Route path='/' element={<Home/>}></Route>
    <Route path="/login" element={<Login/>}></Route>
    <Route path="/user-login" element={<User_Login/>}></Route>
    <Route path='/agent-login' element={<AgentLogin/>}></Route>
    <Route path='/admin-login' element={<AdminLogin/>}></Route>
    <Route path='/admin-dashboard' element={<Admin_dashboard/>}></Route>
    <Route path='/super-admin-dashboard' element={<Super_admin_dashboard/>}></Route>
    <Route path='/user-dashboard' element={<User_dashboard/>}></Route>
    <Route path='/netcron' element={<SuperAdminLogin/>}></Route>
    <Route path='/*' element={<Error/>}></Route>f
    
    </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
