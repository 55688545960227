import React from 'react';
import Footer from './Footer';
import './style.css';
import './responsive.css';
import c1 from '../images/c-1.png';
import c2 from '../images/c-2.png';
import c3 from '../images/c-3.png';
import c4 from '../images/c-4.png';
import c5 from '../images/c-5.png';
import c6 from '../images/c-6.png';
import p1 from '../images/p-1.png';
import p2 from '../images/p-2.png';
import p3 from '../images/p-3.png';
import p4 from '../images/p-4.png';
import p5 from '../images/p-7.png';
import p6 from '../images/p-6.png';
import Header from './Header';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';



export default function Home() {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  return (
    <div>
      <Header/>
      <div className='hero-section'>
        <div className='container'>
          <div className='row py-5'> 
            <div className='col-7 my-auto'>
              <h1>We are here <br/>To Experience You <br/><span className='hero-font'>Our Support & Assistance!</span></h1>
              <div className='text'>
              <p className='my-auto'>We take in a significant amount of effort to make sure you have the best since we are committed to providing you with first-rate service. Our help team can be reached 24/7 using our support ticket system..</p>
              </div>
             
            </div>
            <div className='col-5 '>
             <form className='form'>
              <h5>LET'S REACH OUT </h5>
             
             <TextField  fullWidth size="small" id="name" label="Name" variant="standard" />
             <TextField className='form-font' fullWidth size="small" id="email" label="Email" variant="standard" />
             <TextField className='form-font' fullWidth size="small" id="number" label="Phone Number" variant="standard" />
             <TextField
          id="standard-multiline-static"
          label="Enter Your Query"
          multiline
          rows={1}
          placehoder="Enter Your Query"
          variant="standard"
          fullWidth
        />
            
      
        <button className='form-btn'>Submit</button>
        <p><span className='note'>Note:</span> Thank You Our sales representative will contact you</p>
             
             </form>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
      <h2 className='mt-5'>Our Valuable Partners</h2>
        <div className='row my-5 desktop'>
            <div className='col-lg-2 '>
                <img src={p1} alt="img not found" className='img-fluid'/>
            </div>
            <div className='col-lg-2 '>
                <img src={p2} alt="img not found" className='img-fluid'/>
            </div>
             <div className='col-lg-2 '>
                <img src={p3} alt="img not found" className='img-fluid'/>
            </div> 
            <div className='col-lg-2'>
                <img src={p4} alt="img not found" className='img-fluid'/>
            </div> 
            <div className='col-lg-2'>
                <img src={p5} alt="img not found" className='img-fluid'/>
            </div> 
            <div className='col-lg-2'>
                <img src={p6} alt="img not found" className='img-fluid'/>
            </div>
        </div>
        <div className='phone '>
          <div className='row my-4'>
          <div className='col-6'>
          <img src={p1} alt="img not found" className='img-fluid'/>
          </div>
          <div className='col-6 '>
                <img src={p2} alt="img not found" className='img-fluid'/>
            </div>
        </div>
        <div className='row my-4'>
          <div className='col-6'>
          <img src={p3} alt="img not found" className='img-fluid'/>
          </div>
          <div className='col-6 '>
                <img src={p4} alt="img not found" className='img-fluid'/>
            </div>
        </div>
        <div className='row my-4'>
          <div className='col-6'>
          <img src={p5} alt="img not found" className='img-fluid'/>
          </div>
          <div className='col-6 '>
                <img src={p6} alt="img not found" className='img-fluid'/>
            </div>
        </div>
        </div>
        <h2 className=''>Trusted by 100+ Customers</h2>
        <div className='row my-5 desktop'>
            <div className='col-2'>
                <img src={c1} alt="img not found" className='img-fluid'/>
            </div>
            <div className='col-2'>
                <img src={c2} alt="img not found" className='img-fluid'/>
            </div> <div className='col-2'>
                <img src={c3} alt="img not found" className='img-fluid'/>
            </div> <div className='col-2'>
                <img src={c4} alt="img not found" className='img-fluid'/>
            </div> <div className='col-2'>
                <img src={c5} alt="img not found" className='img-fluid'/>
            </div> <div className='col-2'>
                <img src={c6} alt="img not found" className='img-fluid'/>
            </div>
        </div>
        <div className='phone '>
          <div className='row my-4'>
          <div className='col-6'>
          <img src={c1} alt="img not found" className='img-fluid'/>
          </div>
          <div className='col-6 '>
                <img src={c2} alt="img not found" className='img-fluid'/>
            </div>
        </div>
        <div className='row my-4'>
          <div className='col-6'>
          <img src={c3} alt="img not found" className='img-fluid'/>
          </div>
          <div className='col-6 '>
                <img src={c4} alt="img not found" className='img-fluid'/>
            </div>
        </div>
        <div className='row my-4'>
          <div className='col-6'>
          <img src={c5} alt="img not found" className='img-fluid'/>
          </div>
          <div className='col-6 '>
                <img src={c6} alt="img not found" className='img-fluid'/>
            </div>
        </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}
