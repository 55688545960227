import React from 'react';
import login_support from '../images/Login-support.jpg';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function SuperAdminLogin() {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };
    return (
      <div>
        <div className='login '>
          <div className='row mt-5'>
              <div className='col-2'>
                  
              </div> 
              <div className='col-8'>
                  <div className='card p-4 shadow-lg border-0'>
                      <div className='row'>
                          <div className='col-6'>
                           <h4>Welcome to support portal</h4>
                           <p>Thanks for using our service</p>
                           <img src={login_support} className='img-fluid' alt=""/>
                              
                          </div>
                          <div className='col-6 my-auto'>
                              <div className='login-form my-auto'>
                              <h4>Super Admin Login</h4>
                                  <p>To login please enter your email and password</p>
  
                               
                                  <form className='mx-auto my-auto'>
          <TextField fullWidth  id="standard-basic" label="Email" variant="standard" className='mt-1'/>
          <FormControl sx={{ width: '100%' }} variant="standard" className='mt-3'>
            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
            <Input 
              id="standard-adornment-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end" >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <button className="form-btn my-5">Login</button>
          <a href=""><p>Forget Password ?</p></a>
         
          </form>
  
                               
                                
                              </div>
                              
                          </div>
                          
                      </div>
                  </div>
                  </div>   
                  <div className='col-2'>
                  
                  </div>     
              
          </div>
        </div>
      </div>
    )
  }
  
