import React from 'react';
import'./style.css';
import logo from '../images/logo.png';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function Login() {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  return (
    <div>
    
        
     
      <div className='user-login text-center'>
      <div className='my-auto'>
        <img src={logo} alt="hello"  className='img-fluid col-3 mt-5'/>
        <h6 className=''>Sign In</h6> 
        <form className='col-3  mx-auto my-auto'>
        <TextField fullWidth  id="standard-basic" label="Email" variant="standard" className='mt-4'/>
        <FormControl sx={{ width: '100%' }} variant="standard" className='mt-4'>
          <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
          <Input 
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end" >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <button className="form-btn my-5">Login</button>
        <a href=""><p>Forget Password ?</p></a>
        <a href=""><p>Don't have an account? register new user</p></a>
        </form>
      </div>
    </div>
    </div>
  )
}
