import React from "react";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div>
      <footer className="bg-dark text-light">
        <div className="container-fluid text-center">
          <p className="py-3">
            Copyright © {currentYear} Netcron Technologies :: Support Ticket
            System - All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}
